<template>
  <div class="pagination-arrows">
    <div v-if="shouldDisplayPaginationText" class="pagination-position">
      <template v-if="!isLoading">
        <div class="pagination-text">{{ paginationText }}</div>
      </template>
    </div>
    <div class="icon-wrapper">
      <uds-icon
        icon-name="chevron_left"
        class="icon"
        :class="{ 'is-disabled': currentStateBuilderItem.isLeftArrowDisabled }"
        @click="currentStateBuilderItem.isLeftArrowDisabled ? null : goToLeft()"
      />
      <uds-icon
        icon-name="chevron_right"
        class="icon"
        :class="{ 'is-disabled': currentStateBuilderItem.isRightArrowDisabled }"
        @click="currentStateBuilderItem.isRightArrowDisabled ? null : goToRight()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  workableWidth: number;
  state: string;
  top: boolean;
}>();

const storeComposable = useStoreComposable();
const activeStore = computed(() => storeComposable.activeStore(props.state));
const societyStore = useSocietyStore();

const statePath = computed(() => activeStore.value!);
let link = "";

const shouldDisplayPaginationText = computed(() => {
  return !props.top || (props.workableWidth ?? 0) >= 933;
});

const isLoading = computed(() => {
  return statePath.value.isLoading ?? false;
});

const currentPage = computed(() => {
  // @ts-expect-error
  return statePath.value.currentPage ?? 0;
});

const maxPages = computed(() => {
  // @ts-expect-error
  return statePath.value.maxPages ?? 0;
});

const totalItems = computed(() => {
  // @ts-expect-error
  return statePath.value.totalItems ?? 0;
});

type LinkBuilder = {
  builder: () => string;
};
type LinkArrowProperties = {
  isLoading: boolean;
  isLeftArrowDisabled: boolean;
  isRightArrowDisabled: boolean;
  currentPage: number;
  maxPages: number;
  limit: number;
  totalItems: number;
  itemLength: number;
};

const route = useRoute();

function stateBuilderItem(newCurrentPage: number | null): LinkBuilder & LinkArrowProperties {
  const defaultProperties = {
    ...statePath.value.queryProperties,
    page: newCurrentPage ?? currentPage.value,
  };
  // @ts-expect-error
  const stateItemProperty = statePath.value[useStoreComposable().itemProperties[props.state]];
  const paginationArrowProperties: LinkArrowProperties = {
    isLoading: isLoading.value,
    isLeftArrowDisabled: stateItemProperty?.length === 0 || !(currentPage.value > 1),
    isRightArrowDisabled: stateItemProperty?.length === 0 || currentPage.value === maxPages.value,
    limit: statePath.value.queryProperties.limit ?? 0,
    currentPage: currentPage.value ?? 0,
    maxPages: maxPages.value,
    totalItems: totalItems.value,
    itemLength: totalItems.value,
  };

  const idSociety = useSocietyStore().society?.id ?? 0;

  const linkBuilders: Record<string, LinkBuilder & LinkArrowProperties> = {
    banks: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultBankQueryProperties.value,
          {
            ...defaultProperties,
            // @ts-expect-error
            transactionId: null,
            checkedIds: [],
          },
          "/banks",
        ),
      ...paginationArrowProperties,
    },
    personal: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultBankQueryProperties.value,
          {
            ...defaultProperties,
            // @ts-expect-error
            transactionId: null,
            checkedIds: [],
          },
          "/purchases/expense-notes",
        ),
      ...paginationArrowProperties,
    },
    recovery: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultRecoveryQueryProperties.value,
          // @ts-expect-error
          { ...defaultProperties, itemId: null },
          "/sales/recovery",
        ),
      ...paginationArrowProperties,
    },
    products: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultProductsQueryProperties.value,
          // @ts-expect-error
          { ...defaultProperties, productId: null },
          "/sales/products",
        ),
      ...paginationArrowProperties,
    },
    adminSocieties: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultAdminSocietiesQueryProperties.value,
          { ...defaultProperties },
          "/admin/societies",
        ),
      ...paginationArrowProperties,
    },
    customers: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultThirdPartiesQueryProperties.value,
          // @ts-expect-error
          { ...defaultProperties, thirdPartyId: null },
          "/sales/customers",
        ),
      ...paginationArrowProperties,
    },
    providers: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultThirdPartiesQueryProperties.value,
          // @ts-expect-error
          { ...defaultProperties, thirdPartyId: null },
          "/purchases/providers",
        ),
      ...paginationArrowProperties,
    },
    ztickets: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultZTicketsQueryProperties.value,
          // @ts-expect-error
          { ...defaultProperties, zTicketId: null },
          "/sales/ztickets",
        ),
      ...paginationArrowProperties,
    },
    library: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultLibraryQueryProperties.value,
          // @ts-expect-error
          { ...defaultProperties, documentId: null },
          "/library",
        ),
      ...paginationArrowProperties,
    },
    sales: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultSalesQueryProperties.value,
          // @ts-expect-error
          { ...defaultProperties, itemId: null, checkedIds: [] },
          "/sales",
        ),
      ...paginationArrowProperties,
    },
    auxiliaryLedger: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultAuxiliaryLedgerQueryProperties.value,
          { ...defaultProperties },
          route.name === "purchases-auxiliaryLedger"
            ? "/purchases/auxiliaryLedger"
            : "/sales/auxiliaryLedger",
        ),
      ...paginationArrowProperties,
    },
    employees: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultEmployeesQueryProperties.value,
          { ...defaultProperties },
          "/employees",
        ),
      ...paginationArrowProperties,
    },
    generalLedger: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultGeneralLedgerQueryProperties.value,
          { ...defaultProperties },
          "/balancesheet/generalLedger",
        ),
      ...paginationArrowProperties,
    },
    balance: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultBalanceQueryProperties.value,
          { ...defaultProperties },
          "/balancesheet/balance",
        ),
      ...paginationArrowProperties,
    },
    vat: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultVatQueryProperties.value,
          { ...defaultProperties },
          "/vat",
        ),
      ...paginationArrowProperties,
    },
    adminVat: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultVatQueryProperties.value,
          { ...defaultProperties },
          `/admin/societies/${societyStore.society?.id}/declaration/vat`,
        ),
      ...paginationArrowProperties,
    },
    das2: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultVatQueryProperties.value,
          { ...defaultProperties },
          "/vat/das2",
        ),
      ...paginationArrowProperties,
    },
    companyTaxAdvances: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultCompanyTaxAdvanceQueryProperties.value,
          { ...defaultProperties },
          `/admin/societies/${idSociety}/declaration/companyTaxAdvances`,
        ),
      ...paginationArrowProperties,
    },
    adminDas2: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultVatQueryProperties.value,
          { ...defaultProperties },
          `/admin/societies/${societyStore.society?.id}/declaration/das2`,
        ),
      ...paginationArrowProperties,
    },
    payments: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultPaymentQueryProperties.value,
          { ...defaultProperties },
          "/sales/payments",
        ),
      ...paginationArrowProperties,
    },
    category: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultCategoryQueryProperties.value,
          { ...defaultProperties },
          "/settings/chart-accounts",
        ),
      ...paginationArrowProperties,
    },
    purchases: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultPurchasesQueryProperties.value,
          // @ts-expect-error
          { ...defaultProperties, itemId: null, checkedIds: [] },
          "/purchases",
        ),
      ...paginationArrowProperties,
    },
    balanceSheet: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultBalanceSheetQueryProperties.value,
          // @ts-expect-error
          { ...defaultProperties, itemId: null },
          `/admin/societies/${idSociety}/declaration/balancesheet`,
        ),
      ...paginationArrowProperties,
    },
    adminAnnexCerfa: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultAnnexCerfaQueryProperties.value,
          // @ts-expect-error
          { ...defaultProperties, itemId: null },
          `/admin/societies/${idSociety}/declaration/annex`,
        ),
      ...paginationArrowProperties,
    },
    annexCerfa: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultAnnexCerfaQueryProperties.value,
          // @ts-expect-error
          { ...defaultProperties, itemId: null },
          `/vat/annex`,
        ),
      ...paginationArrowProperties,
    },
    preCompta: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultPreComptaQueryProperties.value,
          { ...defaultProperties },
          `/admin/societies/${societyStore.society?.id}/config/precompta`,
        ),
      ...paginationArrowProperties,
    },
    specificAccountingPlan: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultSpecificAccountingPlanQueryProperties.value,
          { ...defaultProperties },
          `/admin/societies/${societyStore.society?.id}/config/accountplan/specific`,
        ),
      ...paginationArrowProperties,
    },
    persoAccountingPlan: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultPersoAccountingPlanQueryProperties.value,
          { ...defaultProperties },
          `/admin/societies/${societyStore.society?.id}/config/accountplan/perso`,
        ),
      ...paginationArrowProperties,
    },
    adminRevision: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultRevisionQueryProperties.value,
          { ...defaultProperties },
          `/admin/societies/${societyStore.society?.id}/accounting/revisionaccount`,
        ),
      ...paginationArrowProperties,
    },
    adminRevisionCustomer: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultRevisionTPQueryProperties,
          { ...defaultProperties },
          `/admin/societies/${societyStore.society?.id}/accounting/customers`,
        ),
      ...paginationArrowProperties,
    },
    adminRevisionProvider: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultRevisionTPQueryProperties,
          { ...defaultProperties },
          `/admin/societies/${societyStore.society?.id}/accounting/providers`,
        ),
      ...paginationArrowProperties,
    },
    genericAccount: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultGenericAccountQueryProperties.value,
          { ...defaultProperties },
          "/admin/genericaccount",
        ),
      ...paginationArrowProperties,
    },
    expertAccountant: {
      builder: () =>
        useBuildRouteFromQueryPropertiesComposable(
          defaultExpertAccountantQueryProperties.value,
          { ...defaultProperties },
          "/expert/performances",
        ),
      ...paginationArrowProperties,
    },
  };

  return linkBuilders[props.state];
}

const currentStateBuilderItem = computed(() => {
  return stateBuilderItem(null);
});

function buildLinkFromQueryProperties(newCurrentPage: number | null) {
  const linkBuilder = stateBuilderItem(newCurrentPage).builder;

  if (linkBuilder) {
    link = linkBuilder();
  }
}
const positionOfShownItems = computed(() => {
  const positionOfFirstItemShowen = `${
    (currentPage.value - 1) * currentStateBuilderItem.value.limit + 1
  }`;
  const canShowMoreItemsThanLeft =
    currentPage.value * currentStateBuilderItem.value.limit >
    currentStateBuilderItem.value.totalItems;
  const positionOfLastItemShowen = canShowMoreItemsThanLeft
    ? currentStateBuilderItem.value.totalItems
    : currentPage.value * currentStateBuilderItem.value.limit;

  return `${positionOfFirstItemShowen}-${positionOfLastItemShowen}`;
});
const paginationText = computed(() => {
  return `${positionOfShownItems.value} sur ${currentStateBuilderItem.value.totalItems}`;
});

function goToLeft() {
  if (
    !currentStateBuilderItem.value.isLeftArrowDisabled &&
    currentStateBuilderItem.value.itemLength !== 0
  ) {
    buildLinkFromQueryProperties(currentPage.value - 1);
    return navigateTo(link);
  }
}

function goToRight() {
  if (
    !currentStateBuilderItem.value.isRightArrowDisabled &&
    currentStateBuilderItem.value.itemLength !== 0
  ) {
    buildLinkFromQueryProperties(currentPage.value + 1);
    return navigateTo(link);
  }
}
</script>

<style lang="scss" scoped>
.pagination-arrows {
  display: flex;
  align-items: center;

  .pagination-position {
    color: $uds-neutral-600;

    margin-right: 14px;
    min-width: 85px;

    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .icon-wrapper {
    gap: 15px;
    display: flex;
    .icon {
      cursor: pointer;
      color: $uds-neutral-800;
      gap: 15px;

      &.is-disabled {
        opacity: 0.6;
        cursor: default;
      }
    }
  }
}
</style>
